import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  
  return (
    <div className="footer-section section-top">
      <div className="container">
        <div className="row">
          <div className="col-md-6 footer-info-section">
            <h3 className="footer-info-title">
            Join us in embracing the future of education through technology <br></br>– your gateway to a seamlessly managed, technologically enriched institution.
            </h3>
          </div>
          <div className="col-md-6 footer-info-section">
            <h1 className="footer-info-detail">
            NIVID is a transformative solution for Nepal's educational institutions, addressing various challenges through digital innovation. Developed by Axios Softwork, it streamlines administrative processes like curriculum planning, resource allocation, and student performance monitoring. With features promoting collaboration among stakeholders, NIVID fosters a dynamic ecosystem where information flows seamlessly, empowering educators to navigate complex academic landscapes with ease. It represents a paradigm shift in educational management, offering holistic solutions to promote academic excellence and adaptability in the face of evolving educational needs.
            </h1>
            <h2 className="footer-info-detail">
            In a remarkably short span, we have empowered over 300+ educational institutions nationwide with our cutting-edge solutions. Our journey is characterized not only by rapid strides but also by the trust we've earned from schools and colleges alike.
            </h2>
            
          </div>
          <div className="col-md-12 col-lg-10 text-init">
            <p className="footer-title">
              <img
                src={require("../../images/nivid-logo-min.png").default}
                alt="img"
              />
              School Management System
            </p>
            {/* <div className="text-center"> */}
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-4 ">
                <Link to="/">
                  <p
                    className="content"
                  >
                    Home
                  </p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/about-us">
                  <p className="content">About us</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/features">
                  <p className="content">Features</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/our-clients">
                  <p className="content">Our Clients</p>
                </Link>
              </div>
              {/* <div className="col-lg-2 col-md-3 col-sm-3">
                  <Link to="/">
                    <p className="content">Testimonals</p>
                  </Link>
                </div> */}
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="contactus">
                  <p className="content">Contact Us</p>
                </Link>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <Link to="/privacy">
                  <p className="content">Privacy Policy</p>
                </Link>
              </div>
            </div>
            {/* </div> */}
            <p className="content">
              Ⓒ 2022 NIVID School Management System, All rights reserved
            </p>
          </div>
          <div className="col-md-12 col-lg-2 footer-app">
            <div className="app-content">
              <h1 className="sub-content-text mt-10">Get the App</h1>
              <div className="img-content">
                <a
                  href="https://play.google.com/store/apps/details?id=com.nivid.nivid&hl=en&gl=US"
                  target="_blanks"
                >
                  <img
                    className="img-mg"
                    src={require("../../images/GoogleplayBadge.png").default}
                    alt="img"
                  />
                </a>
                <a
                  href="https://apps.apple.com/lc/app/nivid/id1505281357"
                  target="_blanks"
                >
                  <img
                    src={require("../../images/AppBadge.png").default}
                    alt="img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
