import React, { useEffect } from "react";
import Banner from "../Banner/Banner";
import Benefits from "../Benefits/Benefits";
import QualityFeatures from "../QualityFeatures/QualityFeatures";
import WebService from "../WebService/WebService";
import Count from "../Count/Count";
import Clients from "../Clients/Clients";
import MobileApp from "../MobileApp/MobileApp";
import Testimonals from "../Testimonals/Testimonals";
import FAQs from "../FAQs/FAQs";
import Contact from "../Contact/Contact";
import Demo from "../Demo/Demo";
import MetaConfigure from "../helmet/helmet";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaConfigure title="NIVID school management system - digitally upgrading education" />
      <Banner
        home={true}
        title="Experience complete school management system"
        subTitle="A platform to digitize and centralize overall activities of the school"
      />
      {/* <Clients /> */}

      <Benefits Benefits={true} />
      <div className="section-top">
        <QualityFeatures isHome={true} />
      </div>
      <WebService />
      <Count />
      <MobileApp />
      <Testimonals />
      <FAQs isHomePage={true} />
      <div className="section-top">
        <Contact header="true" />
      </div>
      <Demo
        title="We'd love to demonstrate what we can do for you"
        btn_text="Request Demo"
        btn_url="/demo"
      />
    </div>
  );
}

export default Home;
