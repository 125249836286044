import React from "react";
import "./AboutDetails.scss";

function AboutDetails() {
  return (
    <div className="aboutdetails-section ">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="details-content">
              <p className="content">
              NIVID is a transformative solution for Nepal's educational institutions, addressing various challenges through digital innovation. Developed by Axios Softwork, it streamlines administrative processes like curriculum planning, resource allocation, and student performance monitoring. With features promoting collaboration among stakeholders, NIVID fosters a dynamic ecosystem where information flows seamlessly, empowering educators to navigate complex academic landscapes with ease. It represents a paradigm shift in educational management, offering holistic solutions to promote academic excellence and adaptability in the face of evolving educational needs.
              </p>
          
              <p className="content">
                We humbly claim NIVID to be the first company in Nepal to
                provide the top-notch School Management System and services to
                educational institutions.
              </p>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <img
              src={require("../../images/aboutdetails.png").default}
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutDetails;
