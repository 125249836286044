import React from "react";
import "./Count.scss";
import CountUp from "react-countup";

function Count() {
  return (
    <div className="count-section">
      <div className="count-content mgt-30">
        <h2 className="main-title" data-aos-once={true} data-aos="fade-down">
          Some Count that Matters
        </h2>
        <div
          className="content-style"
          data-aos-once={true}
          data-aos="fade-left"
        ></div>
      </div>

      <div className="count-sub-content mgt-30">
        <div className="row">
          <div className="col-md-3">
            <div
              className="sub-content"
              data-aos-once={true}
              data-aos="fade-right"
            >
              <CountUp className="countup" start={0} end={2000} duration={2} />
              <i className="bi bi-plus"></i>
              <p className="content">Teachers</p>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="sub-content  border-left"
              data-aos-once={true}
              data-aos="fade-right"
            >
              <CountUp className="countup" start={0} end={50} duration={2} />
              <span>K</span>
              <i className="bi bi-plus"></i>
              <p className="content">Students</p>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="sub-content border-left col-bd-none"
              data-aos-once={true}
              data-aos="fade-right"
            >
              <CountUp className="countup" start={0} end={300} duration={2} />
              <i className="bi bi-plus"></i>
              <p className="content">Satisfied Clients</p>
            </div>
          </div>

          <div className="col-md-3">
            <div
              className="sub-content border-left"
              data-aos-once={true}
              data-aos="fade-right"
            >
              <CountUp className="countup" start={0} end={5} duration={2} />
              <i className="bi bi-plus"></i>
              <p className="content">Years of journey</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Count;
